import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2a26929e = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _079375d0 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _eb321220 = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _63a811da = () => interopDefault(import('../pages/business/index.vue' /* webpackChunkName: "pages/business/index" */))
const _0eb36b50 = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _d517c3f4 = () => interopDefault(import('../pages/compliance-guard/index.vue' /* webpackChunkName: "pages/compliance-guard/index" */))
const _7ed36204 = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _67eb6064 = () => interopDefault(import('../pages/estate-planner/index.vue' /* webpackChunkName: "pages/estate-planner/index" */))
const _45719cec = () => interopDefault(import('../pages/guides/index.vue' /* webpackChunkName: "pages/guides/index" */))
const _6f0a0afc = () => interopDefault(import('../pages/partnerships/index.vue' /* webpackChunkName: "pages/partnerships/index" */))
const _33cce150 = () => interopDefault(import('../pages/personal-affairs/index.vue' /* webpackChunkName: "pages/personal-affairs/index" */))
const _87cecf26 = () => interopDefault(import('../pages/plus/index.vue' /* webpackChunkName: "pages/plus/index" */))
const _7fddbfae = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _a7576892 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _6510447e = () => interopDefault(import('../pages/real-estate/index.vue' /* webpackChunkName: "pages/real-estate/index" */))
const _52190acd = () => interopDefault(import('../pages/registered-agent/index.vue' /* webpackChunkName: "pages/registered-agent/index" */))
const _596c08fb = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _12bc7252 = () => interopDefault(import('../pages/text-page/index.vue' /* webpackChunkName: "pages/text-page/index" */))
const _7fec3f1c = () => interopDefault(import('../pages/account/companies/index.vue' /* webpackChunkName: "pages/account/companies/index" */))
const _1f7c008e = () => interopDefault(import('../pages/account/documents/index.vue' /* webpackChunkName: "pages/account/documents/index" */))
const _3126fdbf = () => interopDefault(import('../pages/account/estate-planning/index.vue' /* webpackChunkName: "pages/account/estate-planning/index" */))
const _1e15162a = () => interopDefault(import('../pages/account/login/index.vue' /* webpackChunkName: "pages/account/login/index" */))
const _938d7972 = () => interopDefault(import('../pages/account/products-library/index.vue' /* webpackChunkName: "pages/account/products-library/index" */))
const _fdfc822c = () => interopDefault(import('../pages/account/profile/index.vue' /* webpackChunkName: "pages/account/profile/index" */))
const _42483a1c = () => interopDefault(import('../pages/account/real-estate/index.vue' /* webpackChunkName: "pages/account/real-estate/index" */))
const _cb2dade4 = () => interopDefault(import('../pages/account/reset-password/index.vue' /* webpackChunkName: "pages/account/reset-password/index" */))
const _93c6eec6 = () => interopDefault(import('../pages/account/search/index.vue' /* webpackChunkName: "pages/account/search/index" */))
const _7a052eea = () => interopDefault(import('../pages/business-formation/llc/index.vue' /* webpackChunkName: "pages/business-formation/llc/index" */))
const _150c0345 = () => interopDefault(import('../pages/business-formation/llc-packages/index.vue' /* webpackChunkName: "pages/business-formation/llc-packages/index" */))
const _1d2432c7 = () => interopDefault(import('../pages/business/state-filing/index.vue' /* webpackChunkName: "pages/business/state-filing/index" */))
const _5874fbc6 = () => interopDefault(import('../pages/documents/create/index.vue' /* webpackChunkName: "pages/documents/create/index" */))
const _7839e1fe = () => interopDefault(import('../pages/estate-planner/last-will/index.vue' /* webpackChunkName: "pages/estate-planner/last-will/index" */))
const _5a9ac5c9 = () => interopDefault(import('../pages/orders/create/index.vue' /* webpackChunkName: "pages/orders/create/index" */))
const _9f32704e = () => interopDefault(import('../pages/partnerships/banking/index.vue' /* webpackChunkName: "pages/partnerships/banking/index" */))
const _ddf4d660 = () => interopDefault(import('../pages/partnerships/business-insurance/index.vue' /* webpackChunkName: "pages/partnerships/business-insurance/index" */))
const _338636af = () => interopDefault(import('../pages/account/estate-planning/bestow.vue' /* webpackChunkName: "pages/account/estate-planning/bestow" */))
const _6d709c7b = () => interopDefault(import('../pages/account/partners/business-banking.vue' /* webpackChunkName: "pages/account/partners/business-banking" */))
const _275d1422 = () => interopDefault(import('../pages/account/partners/business-insurance.vue' /* webpackChunkName: "pages/account/partners/business-insurance" */))
const _15628464 = () => interopDefault(import('../pages/account/partners/home-insurance.vue' /* webpackChunkName: "pages/account/partners/home-insurance" */))
const _0ec3fdaa = () => interopDefault(import('../pages/account/partners/life-insurance.vue' /* webpackChunkName: "pages/account/partners/life-insurance" */))
const _676a3312 = () => interopDefault(import('../pages/business-formation/llc/california/index.vue' /* webpackChunkName: "pages/business-formation/llc/california/index" */))
const _a73b8054 = () => interopDefault(import('../pages/business-formation/llc/create/index.vue' /* webpackChunkName: "pages/business-formation/llc/create/index" */))
const _578e64d9 = () => interopDefault(import('../pages/business-formation/llc/florida/index.vue' /* webpackChunkName: "pages/business-formation/llc/florida/index" */))
const _1b4e355e = () => interopDefault(import('../pages/business-formation/llc/form/index.vue' /* webpackChunkName: "pages/business-formation/llc/form/index" */))
const _0d3c20e5 = () => interopDefault(import('../pages/business-formation/llc/illinois/index.vue' /* webpackChunkName: "pages/business-formation/llc/illinois/index" */))
const _b82e0214 = () => interopDefault(import('../pages/business-formation/llc/new-york/index.vue' /* webpackChunkName: "pages/business-formation/llc/new-york/index" */))
const _2b7c9f64 = () => interopDefault(import('../pages/business-formation/llc/open/index.vue' /* webpackChunkName: "pages/business-formation/llc/open/index" */))
const _38cc825e = () => interopDefault(import('../pages/business-formation/llc/start/index.vue' /* webpackChunkName: "pages/business-formation/llc/start/index" */))
const _a683f856 = () => interopDefault(import('../pages/business-formation/llc/texas/index.vue' /* webpackChunkName: "pages/business-formation/llc/texas/index" */))
const _7e359dd4 = () => interopDefault(import('../pages/account/companies/_slug.vue' /* webpackChunkName: "pages/account/companies/_slug" */))
const _22e9431e = () => interopDefault(import('../pages/account/documents/_slug.vue' /* webpackChunkName: "pages/account/documents/_slug" */))
const _42c4d9cf = () => interopDefault(import('../pages/documents/new/_documentId.vue' /* webpackChunkName: "pages/documents/new/_documentId" */))
const _0dc5ce23 = () => interopDefault(import('../pages/orders/new/_orderId.vue' /* webpackChunkName: "pages/orders/new/_orderId" */))
const _2659ca02 = () => interopDefault(import('../pages/business-formation/_entity_type.vue' /* webpackChunkName: "pages/business-formation/_entity_type" */))
const _74dc0952 = () => interopDefault(import('../pages/categories/_parent_slug/index.vue' /* webpackChunkName: "pages/categories/_parent_slug/index" */))
const _e936f16c = () => interopDefault(import('../pages/checkout/_planId.vue' /* webpackChunkName: "pages/checkout/_planId" */))
const _48dedf7c = () => interopDefault(import('../pages/guides/_slug.vue' /* webpackChunkName: "pages/guides/_slug" */))
const _679512a2 = () => interopDefault(import('../pages/categories/_parent_slug/_product_slug/index.vue' /* webpackChunkName: "pages/categories/_parent_slug/_product_slug/index" */))
const _5d19a14d = () => interopDefault(import('../pages/lp/_id/_product_slug/index.vue' /* webpackChunkName: "pages/lp/_id/_product_slug/index" */))
const _47c6c3b9 = () => interopDefault(import('../pages/lpd/_id/_product_slug/index.vue' /* webpackChunkName: "pages/lpd/_id/_product_slug/index" */))
const _430e4f0a = () => interopDefault(import('../pages/recover/_order_type/_id.vue' /* webpackChunkName: "pages/recover/_order_type/_id" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _fd214c94 = () => interopDefault(import('../pages/lp/_id/_product_slug/index.vue' /* webpackChunkName: "" */))
const _a3afcf20 = () => interopDefault(import('../pages/lpd/_id/_product_slug/index.vue' /* webpackChunkName: "" */))
const _655aae3e = () => interopDefault(import('../pages/account/profile/index.vue' /* webpackChunkName: "" */))
const _0c1833ea = () => interopDefault(import('../pages/account/companies/_slug.vue' /* webpackChunkName: "" */))
const _5aa8fef3 = () => interopDefault(import('../pages/checkout/_planId.vue' /* webpackChunkName: "" */))
const _5fc3e5b3 = () => interopDefault(import('../pages/guides/index.vue' /* webpackChunkName: "" */))
const _4b3bc180 = () => interopDefault(import('../pages/orders/create/index.vue' /* webpackChunkName: "" */))
const _03326c4c = () => interopDefault(import('../pages/orders/new/_orderId.vue' /* webpackChunkName: "" */))
const _d37d5ea2 = () => interopDefault(import('../pages/documents/create/index.vue' /* webpackChunkName: "" */))
const _6fdc1f74 = () => interopDefault(import('../pages/documents/new/_documentId.vue' /* webpackChunkName: "" */))
const _1e03eb39 = () => interopDefault(import('../pages/business-formation/_entity_type.vue' /* webpackChunkName: "" */))
const _482e85b8 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "" */))
const _4cc9f874 = () => interopDefault(import('../pages/account/search/index.vue' /* webpackChunkName: "" */))
const _fb16ccc0 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "" */))
const _00a5e189 = () => interopDefault(import('../pages/text-page/index.vue' /* webpackChunkName: "" */))
const _628ac1a0 = () => interopDefault(import('../pages/account/products-library/index.vue' /* webpackChunkName: "" */))
const _e92c48e6 = () => interopDefault(import('../pages/estate-planner/index.vue' /* webpackChunkName: "" */))
const _de898432 = () => interopDefault(import('../pages/estate-planner/last-will/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _2a26929e,
    name: "about-us"
  }, {
    path: "/account",
    component: _079375d0,
    name: "account"
  }, {
    path: "/affiliate",
    component: _eb321220,
    name: "affiliate"
  }, {
    path: "/business",
    component: _63a811da,
    name: "business"
  }, {
    path: "/careers",
    component: _0eb36b50,
    name: "careers"
  }, {
    path: "/compliance-guard",
    component: _d517c3f4,
    name: "compliance-guard"
  }, {
    path: "/contact-us",
    component: _7ed36204,
    name: "contact-us"
  }, {
    path: "/estate-planner",
    component: _67eb6064,
    name: "estate-planner"
  }, {
    path: "/guides",
    component: _45719cec,
    name: "guides"
  }, {
    path: "/partnerships",
    component: _6f0a0afc,
    name: "partnerships"
  }, {
    path: "/personal-affairs",
    component: _33cce150,
    name: "personal-affairs"
  }, {
    path: "/plus",
    component: _87cecf26,
    name: "plus"
  }, {
    path: "/pricing",
    component: _7fddbfae,
    name: "pricing"
  }, {
    path: "/products",
    component: _a7576892,
    name: "products"
  }, {
    path: "/real-estate",
    component: _6510447e,
    name: "real-estate"
  }, {
    path: "/registered-agent",
    component: _52190acd,
    name: "registered-agent"
  }, {
    path: "/search",
    component: _596c08fb,
    name: "search"
  }, {
    path: "/text-page",
    component: _12bc7252,
    name: "text-page"
  }, {
    path: "/account/companies",
    component: _7fec3f1c,
    name: "account-companies"
  }, {
    path: "/account/documents",
    component: _1f7c008e,
    name: "account-documents"
  }, {
    path: "/account/estate-planning",
    component: _3126fdbf,
    name: "account-estate-planning"
  }, {
    path: "/account/login",
    component: _1e15162a,
    name: "account-login"
  }, {
    path: "/account/products-library",
    component: _938d7972,
    name: "account-products-library"
  }, {
    path: "/account/profile",
    component: _fdfc822c,
    name: "account-profile"
  }, {
    path: "/account/real-estate",
    component: _42483a1c,
    name: "account-real-estate"
  }, {
    path: "/account/reset-password",
    component: _cb2dade4,
    name: "account-reset-password"
  }, {
    path: "/account/search",
    component: _93c6eec6,
    name: "account-search"
  }, {
    path: "/business-formation/llc",
    component: _7a052eea,
    name: "business-formation-llc"
  }, {
    path: "/business-formation/llc-packages",
    component: _150c0345,
    name: "business-formation-llc-packages"
  }, {
    path: "/business/state-filing",
    component: _1d2432c7,
    name: "business-state-filing"
  }, {
    path: "/documents/create",
    component: _5874fbc6,
    name: "documents-create"
  }, {
    path: "/estate-planner/last-will",
    component: _7839e1fe,
    name: "estate-planner-last-will"
  }, {
    path: "/orders/create",
    component: _5a9ac5c9,
    name: "orders-create"
  }, {
    path: "/partnerships/banking",
    component: _9f32704e,
    name: "partnerships-banking"
  }, {
    path: "/partnerships/business-insurance",
    component: _ddf4d660,
    name: "partnerships-business-insurance"
  }, {
    path: "/account/estate-planning/bestow",
    component: _338636af,
    name: "account-estate-planning-bestow"
  }, {
    path: "/account/partners/business-banking",
    component: _6d709c7b,
    name: "account-partners-business-banking"
  }, {
    path: "/account/partners/business-insurance",
    component: _275d1422,
    name: "account-partners-business-insurance"
  }, {
    path: "/account/partners/home-insurance",
    component: _15628464,
    name: "account-partners-home-insurance"
  }, {
    path: "/account/partners/life-insurance",
    component: _0ec3fdaa,
    name: "account-partners-life-insurance"
  }, {
    path: "/business-formation/llc/california",
    component: _676a3312,
    name: "business-formation-llc-california"
  }, {
    path: "/business-formation/llc/create",
    component: _a73b8054,
    name: "business-formation-llc-create"
  }, {
    path: "/business-formation/llc/florida",
    component: _578e64d9,
    name: "business-formation-llc-florida"
  }, {
    path: "/business-formation/llc/form",
    component: _1b4e355e,
    name: "business-formation-llc-form"
  }, {
    path: "/business-formation/llc/illinois",
    component: _0d3c20e5,
    name: "business-formation-llc-illinois"
  }, {
    path: "/business-formation/llc/new-york",
    component: _b82e0214,
    name: "business-formation-llc-new-york"
  }, {
    path: "/business-formation/llc/open",
    component: _2b7c9f64,
    name: "business-formation-llc-open"
  }, {
    path: "/business-formation/llc/start",
    component: _38cc825e,
    name: "business-formation-llc-start"
  }, {
    path: "/business-formation/llc/texas",
    component: _a683f856,
    name: "business-formation-llc-texas"
  }, {
    path: "/account/companies/:slug",
    component: _7e359dd4,
    name: "account-companies-slug"
  }, {
    path: "/account/documents/:slug",
    component: _22e9431e,
    name: "account-documents-slug"
  }, {
    path: "/documents/new/:documentId?",
    component: _42c4d9cf,
    name: "documents-new-documentId"
  }, {
    path: "/orders/new/:orderId?",
    component: _0dc5ce23,
    name: "orders-new-orderId"
  }, {
    path: "/business-formation/:entity_type",
    component: _2659ca02,
    name: "business-formation-entity_type"
  }, {
    path: "/categories/:parent_slug",
    component: _74dc0952,
    name: "categories-parent_slug"
  }, {
    path: "/checkout/:planId?",
    component: _e936f16c,
    name: "checkout-planId"
  }, {
    path: "/guides/:slug",
    component: _48dedf7c,
    name: "guides-slug"
  }, {
    path: "/categories/:parent_slug?/:product_slug",
    component: _679512a2,
    name: "categories-parent_slug-product_slug"
  }, {
    path: "/lp/:id?/:product_slug",
    component: _5d19a14d,
    name: "lp-id-product_slug"
  }, {
    path: "/lpd/:id?/:product_slug",
    component: _47c6c3b9,
    name: "lpd-id-product_slug"
  }, {
    path: "/recover/:order_type?/:id?",
    component: _430e4f0a,
    name: "recover-order_type-id"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/lp/:id/:productSlug",
    component: _fd214c94,
    name: "test-landers"
  }, {
    path: "/lpd/:id/:productSlug",
    component: _a3afcf20,
    name: "preview-landers"
  }, {
    path: "/account/profile/:page",
    component: _655aae3e,
    name: "profile-pages"
  }, {
    path: "/account/companies/:slug/:page",
    component: _0c1833ea,
    name: "company-pages"
  }, {
    path: "/checkout/:planID",
    component: _5aa8fef3,
    name: "subscription-checkout"
  }, {
    path: "/guides/page/:page",
    component: _5fc3e5b3,
    name: "guide-pages"
  }, {
    path: "/guides/categories/:categorySlug",
    component: _5fc3e5b3,
    name: "guide-category"
  }, {
    path: "/guides/categories/:categorySlug/page/:page",
    component: _5fc3e5b3,
    name: "guide-category-pages"
  }, {
    path: "/orders/create/:orderType/:orderFormVersion?",
    component: _4b3bc180,
    name: "orders-create"
  }, {
    path: "/orders/new/:orderId/:currentPageId?",
    component: _03326c4c,
    name: "orders-new"
  }, {
    path: "/documents/create/:product/:productVersion?",
    component: _d37d5ea2,
    name: "documents-create"
  }, {
    path: "/documents/new/:documentId/:currentPageId?",
    component: _6fdc1f74,
    name: "documents-new"
  }, {
    path: "/business-formation",
    component: _1e03eb39,
    name: "business-formation-index"
  }, {
    path: "/search/page/:page",
    component: _482e85b8,
    name: "search-pages"
  }, {
    path: "/account/search/page/:page",
    component: _4cc9f874,
    name: "account-search-pages"
  }, {
    path: "/products/:type",
    component: _fb16ccc0,
    name: "products"
  }, {
    path: "/privacy-policy",
    component: _00a5e189,
    name: "privacy-policy"
  }, {
    path: "/terms-of-use",
    component: _00a5e189,
    name: "terms-of-use"
  }, {
    path: "/supplemental-terms-of-service",
    component: _00a5e189,
    name: "supplemental-terms-of-service"
  }, {
    path: "/cookie-policy",
    component: _00a5e189,
    name: "cookie-policy"
  }, {
    path: "/account/products-library",
    component: _628ac1a0,
    name: "new-document"
  }, {
    path: "/estate-planner",
    component: _e92c48e6,
    name: "estate-planner"
  }, {
    path: "/estate-planner/last-will",
    component: _de898432,
    name: "estate-planner-will"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
